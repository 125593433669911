import React, { useState } from 'react';
import { MapContainer, TileLayer, WMSTileLayer, LayersControl, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './App.css'; // Import CSS file for styling
function App() {

  const subdistrictWMSUrl = 'https://airvista.soc.cmu.ac.th:8043/geoserver/d4d_geoserver/wms?service=WMS&request=GetMap&layers=sub_district_17n_d4d_wgs1984&format=image/png&transparent=true';
  const districtWMSUrl = 'https://airvista.soc.cmu.ac.th:8043/geoserver/d4d_geoserver/wms?service=WMS&request=GetMap&layers=district_17n_d4d_wgs1984&format=image/png&transparent=true';
  const provinceWMSUrl = 'https://airvista.soc.cmu.ac.th:8043/geoserver/d4d_geoserver/wms?service=WMS&request=GetMap&layers=province_17n_d4d_wgs1984&format=image/png&transparent=true';

  const hotspot24hMODIS = 'https://airvista.soc.cmu.ac.th:8043/geoserver/hotspots/wms?service=WMS&request=GetMap&layers=hotspots:sea_modis_hotspot_24h&format=image/png&transparent=true';
  const hotspot24hVIIRSsnpp = 'https://airvista.soc.cmu.ac.th:8043/geoserver/hotspots/wms?service=WMS&request=GetMap&layers=hotspots:sea_snpp_viirs_hotspot_24h&format=image/png&transparent=true';
  const hotspot24hVIIRSnoaa20 = 'https://airvista.soc.cmu.ac.th:8043/geoserver/hotspots/wms?service=WMS&request=GetMap&layers=hotspots:sea_noaa20_viirs_hotspot_24h&format=image/png&transparent=true';
  const hotspot24hVIIRSnoaa21 = 'https://airvista.soc.cmu.ac.th:8043/geoserver/hotspots/wms?service=WMS&request=GetMap&layers=hotspots:sea_noaa21_viirs_hotspot_24h&format=image/png&transparent=true';
 

  //const Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
//attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'

  const [selectedLayer, setSelectedLayer] = useState('osm');
  const [clickPosition, setClickPosition] = useState(null);

  const handleLayerChange = (event) => {
    setSelectedLayer(event.target.value);
  };

  const handleMapClick = (e) => {
    setClickPosition(e.latlng);
  };
  // Specify the initial center and zoom level
  const initialCenter = [18.78, 98.98]; // Example center point
  const initialZoom = 10; // Example zoom level
  const maxZoom = 20; // Example zoom level
  const minZoom = 6; // Example zoom level
  return (
    <div className="App">
      <MapContainer center={initialCenter} zoom={initialZoom} maxZoom={maxZoom} minZoom={minZoom} style={{ height: '100vh', width: '100vw' }}>
      <LayersControl position="topright">

        <LayersControl.BaseLayer name="OpenStreetMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
        

        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'"
          attribution='&copy; <a href="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        />//
         <LayersControl.Overlay checked name="ขอบเขตประเทศ">
        <WMSTileLayer
           layers="world_administrative_boundaries"
           url={'https://airvista.soc.cmu.ac.th:8043/geoserver/hotspots/wms?service=WMS&request=GetMap&layers=world_administrative_boundaries&format=image/png&transparent=true'}
           transparent={true}
           format="image/png"
        />
         </LayersControl.Overlay>

        <LayersControl.Overlay checked name="ขอบจังหวัด">
        <WMSTileLayer
           layers="province_17n_d4d_wgs1984"
           url={provinceWMSUrl}
           transparent={true}
           format="image/png"
        />
      </LayersControl.Overlay>
  
      <LayersControl.Overlay checked name="ขอบเขตอำเภอ">
        <WMSTileLayer
           layers="district_17n_d4d_wgs1984"
           url={districtWMSUrl}
           transparent={true}
           format="image/png"
         />
      </LayersControl.Overlay>

      <LayersControl.Overlay name="ขอบเขตตำบล">
        <WMSTileLayer
           layers="sub_district_17n_d4d_wgs1984"
           url={subdistrictWMSUrl}
           transparent={true}
           format="image/png"
        />
      </LayersControl.Overlay> 

      <LayersControl.Overlay position="topleft" checked name="จุดความร้อน VIIRS-SNPP 24 ชั่วโมงล่าสุด">
        <WMSTileLayer
          layers="hotspots:sea_snpp_viirs_hotspot_24h"
          url={hotspot24hVIIRSsnpp}
          transparent={true}
          format="image/png"
        />
      </LayersControl.Overlay>
      <LayersControl.Overlay position="topleft" checked name="จุดความร้อน VIIRS-NOAA-20 24 ชั่วโมงล่าสุด">
        <WMSTileLayer
          layers="hotspots:sea_noaa20_viirs_hotspot_24h"
          url={hotspot24hVIIRSnoaa20}
          transparent={true}
          format="image/png"
        />
      </LayersControl.Overlay>
      <LayersControl.Overlay checked name="จุดความร้อน VIIRS-NOAA-21 24 ชั่วโมงล่าสุด">
        <WMSTileLayer
          layers="hotspots:sea_noaa21_viirs_hotspot_24h"
          url={hotspot24hVIIRSnoaa21}
          transparent={true}
          format="image/png"
        />
      </LayersControl.Overlay>
      <LayersControl.Overlay checked name="จุดความร้อน MODIS 24 ชั่วโมงล่าสุด">
        <WMSTileLayer
          layers="hotspots:sea_modis_hotspot_24h"
          url={hotspot24hMODIS}
          transparent={true}
          format="image/png"
        />
      </LayersControl.Overlay>
    
             {clickPosition && (
            <Popup position={clickPosition}>
              Latitude: {clickPosition.lat.toFixed(6)}, Longitude: {clickPosition.lng.toFixed(6)}
            </Popup>
          )}
         </LayersControl>
      </MapContainer>
    </div>
  );
}

export default App;
